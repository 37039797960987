import React from "react";

import { CaseStudies, Layout, Services, Hero, CTA, Crew } from "../components";

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Services />
      <CTA />
      <Crew />
      <CaseStudies />
    </Layout>
  );
}
